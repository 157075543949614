import React from 'react';

// Styles
import '../styles/button.scss';

export const PrimaryButton = ({ children, ...props }) => {
	return (
		<button className='btn primary-btn' {...props}>
			{children || 'No text provided'}
		</button>
	);
};
