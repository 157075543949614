import React from 'react';

// Styles
import '../../styles/usecases.scss';

// Footer component
import Footer from '../footer';

// React router and scroll libraries
import { Link } from 'react-router-dom';

// Button component
import { PrimaryButton } from '../../components/Button';

import banner from '../../assets/usecase/real-estate-banner.svg';
import incogBanner from '../../assets/usecase/incog-dlp-banner.svg';
import Card from '../../components/Card';
import Bullet from '../../components/Bullet';
import multipleSystemBanner from '../../assets/usecase/multi-system-nodes.svg';
import smartFileBanner from '../../assets/usecase/smart-file-logger.svg';
import singleWindowBanner from '../../assets/usecase/single-window-remediation-dlp.svg';
import BottomDemo from './BottomDemo';

export default function RealEstate() {
	return (
		<div>
			<section className='container usecase-header'>
				<div className='usecase-header-text-div'>
					<h1 className='usecase-header-title'>Real Estate</h1>
					<p>
						Data loss is the biggest threat to a Real Estate
						organisation. Incog DLP ensures that the data remains
						within your organisation and can keep live track of the
						complete life cycle of the files within your
						organisation’s system. No unnecessary data creation or
						sharing that may lead to data leakage. Any malicious
						activity can be flagged and remediated by the admin in
						real time.
					</p>
					<div className='buttons'>
						<Link to='/incog-dlp-demo'>
							<PrimaryButton>Schedule a demo</PrimaryButton>
						</Link>
					</div>
				</div>
				<div className='usecase-header-img-div'>
					<img src={banner} alt='' loading='lazy' />
				</div>
			</section>

			<section
				className='container usecase-header'
				style={{
					flexDirection: 'column',
					width: '100%',
					alignItems: 'center',
					textAlign: 'center',
					paddingTop: 100,
				}}
			>
				<div
					className='usecase-header-text-div'
					style={{
						width: '80%',
						padding: '0',
					}}
				>
					<h1 className='usecase-header-title-2'>
						Incog DLP at a glance
						<hr />
					</h1>
					<p>
						Incog is a state-of-the-art data loss prevention suite
						that meets all the requirements of your organisation for
						protecting your data from leakage within the
						organisation. It is your one stop solution for threat
						detection and remediation within your organisation’s
						systems.
					</p>
				</div>
				<div
					className='usecase-header-img-div'
					style={{
						width: '100%',
						marginTop: -40,
					}}
				>
					<img
						style={{
							boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.1)',
						}}
						src={incogBanner}
						alt=''
						loading='lazy'
					/>
				</div>
				<div className='usecase-card-div'>
					<Card className='usecase-card-wrapper'>
						<h1>What you get with Incog?</h1>
						<div className='usecase-points'>
							<Bullet type='box'>Unified DLP Dashboard</Bullet>
							<Bullet type='box'>
								User Grained Permission Access
							</Bullet>
							<Bullet type='box'>Live Node Viewer</Bullet>
							<Bullet type='box'>Offline Node Logging</Bullet>
							<Bullet type='box'>Secured Delete</Bullet>
							<Bullet type='box'>
								Threat Flagging Mechanism
							</Bullet>
						</div>
					</Card>
					<Card className='usecase-card-wrapper'>
						<h1>Why do you need Incog?</h1>
						<div className='usecase-points'>
							<Bullet type='nav'>One-stop DLP solution</Bullet>
							<Bullet type='nav'>
								Stop Internal Data Leakage
							</Bullet>
							<Bullet type='nav'>
								Cost-effective Data Management
							</Bullet>
							<Bullet type='nav'>
								Protection from Penalties
							</Bullet>
							<Bullet type='nav'>
								Efficient Data Breach Management{' '}
							</Bullet>
							<Bullet type='nav'>Smooth Auditing</Bullet>
						</div>
					</Card>
				</div>
			</section>
			<div className='reachout-container'>
				<h3>Reach out to us now!</h3>
				<Link to='/incog-dlp-demo'>
					<PrimaryButton>Schedule a demo</PrimaryButton>
				</Link>
			</div>
			<div className='keyfeature-container'>
				<h1 className='primary-header-title'>
					Key features of Incog DLP
					<hr />
				</h1>

				{/* Section 1 */}
				<section className='keyfeature-section ltr'>
					<div className='keyfeature-section-img-div'>
						<img src={multipleSystemBanner} alt='' loading='lazy' />
					</div>
					<div className='keyfeature-section-text-div'>
						<h2 className='keyfeature-section-title'>
							Multiple system nodes
						</h2>
						<p className='keyfeature-section-description'>
							Your organisation may have different systems having
							different Operating Systems/File Systems i.e MacOS,
							Windows, LINUX or even servers. Each and every one
							of these systems can be added through the dashboard
							to monitor for data loss prevention in a privacy
							preserving manner.
						</p>
						<div className='keyfeature-points-container'>
							<Bullet type='check'>Windows Nodes</Bullet>
							<Bullet type='check'>MacOS Nodes</Bullet>
							<Bullet type='check'>LINUX Nodes</Bullet>
							<Bullet type='check'>Server Nodes</Bullet>
						</div>
					</div>
				</section>

				{/* Section 2 */}
				<section className='keyfeature-section rtl'>
					<div className='keyfeature-section-img-div'>
						<img src={smartFileBanner} alt='' loading='lazy' />
					</div>
					<div className='keyfeature-section-text-div'>
						<h2 className='keyfeature-section-title'>
							Smart file logger
						</h2>
						<p className='keyfeature-section-description'>
							For every data generated at each system whether the
							system is offline or online the logs are generated
							and can be accessed through the DLP dashboard for
							threat detection, flagging and remediation.
						</p>
						<div className='keyfeature-points-container'>
							<Bullet type='check'>Smart File log</Bullet>
							<Bullet type='check'>
								Dashboard Data Visualisation
							</Bullet>
						</div>
					</div>
				</section>

				{/* Section 3 */}
				<section className='keyfeature-section ltr'>
					<div className='keyfeature-section-img-div'>
						<img src={singleWindowBanner} alt='' loading='lazy' />
					</div>
					<div className='keyfeature-section-text-div'>
						<h2 className='keyfeature-section-title'>
							Single Window Remediation
						</h2>
						<p className='keyfeature-section-description'>
							Your data management team can take the necessary
							action on the data generated in a system to meet
							regulatory compliances through the single dashboard.
							You can delete, anonymise or even encrypt data as
							necessary and save yourself from hefty penalties.
							This will ensure 100% data privacy law compliance
							and smooth auditing.
						</p>
						<div className='keyfeature-points-container'>
							<Bullet type='check'>Secured Delete</Bullet>
							<Bullet type='check'>Anonymisation</Bullet>
							<Bullet type='check'>Redaction</Bullet>
							<Bullet type='check'>Encryption</Bullet>
						</div>
					</div>
				</section>
			</div>

			<BottomDemo to='/incog-dlp-demo' />
			<Footer />
		</div>
	);
}
