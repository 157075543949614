import React from 'react';
import '../styles/card.scss';

export default function Card({ children, style, className, onClick, ...props }) {
	return (
		<div
			className={className + ' card-container'}
			style={{
				cursor: onClick && 'pointer',
				borderBottomColor: onClick ? 'transparent' : '#39c0ba',
				...style,
			}}
			onClick={onClick}
			{...props}
		>
			{children}
		</div>
	);
}
