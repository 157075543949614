import React, { useState, useEffect } from 'react';

// Styles
import '../styles/home.scss';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/autoplay/autoplay.min.css';

// Footer component
import Footer from './footer';

// Assets
import banner from '../assets/header-image.svg';
import rightArrow from '../assets/arrow-right.png';
import disectoAbout from '../assets/disecto-about.svg';
import privacyImage from '../assets/privacy-image.svg';
import incogIcon from '../assets/incog-icon.svg';
import ekaantIcon from '../assets/ekaant-icon.svg';

// React router and scroll libraries
import ScrollIntoView from 'react-scroll-into-view';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';


// Text Transition
import { presets } from 'react-text-transition';
import TextTransition from 'react-text-transition';

// Components
import Card from '../components/Card';
import Bullet from '../components/Bullet';
import Blogcard from '../components/Blogcard';

function Home() {
	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};

	// Text transition
	const TEXTS = ['Discovery', 'Classification', 'Anonymization'];
	const [index, setIndex] = useState(0);


	useEffect(() => {
		const intervalId = setInterval(
			() => setIndex((index) => index + 1),
			2000 // every 2 seconds
		);
		return () => clearTimeout(intervalId);
	}, []);

	// Articles 
	const blogs = [
		{
			blogId: 1,
			blogURL: 'https://www.forbes.com/sites/garydrenik/2023/02/02/data-security--privacy-trends-for-2023/',
			blogTitle: 'Data Security & Privacy Trends For 2023',
			imageURL: 'https://imageio.forbes.com/specials-images/imageserve/63d41797787221ae9ba89c47/Data-Security/960x0.jpg?format=jpg&width=960',
			publishDate: '2 Feb 2023',
			readTime: '7 min'
		},
		{
			blogId: 2,
			blogURL: 'https://www.ft.com/content/4a647843-84ce-458b-8494-782166705f07',
			blogTitle: 'Corporate ‘privacy’ concerns must not derail Europe’s Data Act',
			imageURL: 'https://www.ft.com/__origami/service/image/v2/images/raw/https%3A%2F%2Fd1e00ek4ebabms.cloudfront.net%2Fproduction%2F27306a96-b5dd-4076-9fb1-2d52e0f94955.jpg?dpr=2&fit=scale-down&quality=medium&source=next&width=700',
			publishDate: '2 Feb 2023',
			readTime: '7 min'
		},
		{
			blogId: 3,
			blogURL: 'https://venturebeat.com/security/why-confidential-computing-will-be-critical-to-not-so-distant-future-data-security-efforts/',
			blogTitle: 'Why confidential computing will be critical to (not so distant) future data security efforts',
			imageURL: 'https://venturebeat.com/wp-content/uploads/2022/07/GettyImages-security_1319016122.jpg?fit=750%2C500&strip=all',
			publishDate: '2 Feb 2023',
			readTime: '7 min'
		},
		{
			blogId: 4,
			blogURL: 'https://www.sandiegouniontribune.com/news/health/story/2023-02-06/sharp-notified-x-patients-of-data-breach',
			blogTitle: 'Sharp notifies nearly 63,000 patients of data breach',
			imageURL: 'https://ca-times.brightspotcdn.com/dims4/default/79f42d9/2147483647/strip/true/crop/6720x4480+0+0/resize/1200x800!/format/webp/quality/80/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2F08%2Fc6%2F3b00b46344afa4c010809b6e3ac5%2F475233-sd-me-sharp-chula-vista-hl-003.jpg',
			publishDate: '6 Feb 2023',
			readTime: '2 min'
		},
	]

	return (
		<div>
			{/* Header */}
			<section className='container header'>
				<div className='header-text-div'>
					<h1 className='header-title'>
						Automated & efficient <br />
						<span className='text-data'> Data</span>
						<span className='text-transition'>
							<TextTransition text={TEXTS[index % TEXTS.length]}
								springConfig={presets.gentle}
								direction='up'
								inline={true} /> </span>
					</h1>
					<p>
						Meet all your Data Privacy and Security requirements with
						Disecto’s Single SaaS platform
						Incog. All the while saving at least 40% per 1 GB of
						data discovered.

					</p>
					<div className='buttons'>
						<Link to='/contact'>
							<button className='btn contact-btn'>
								Contact us
							</button>
						</Link>

						<ScrollIntoView selector='#about-us' smooth={true}>
							<button className='btn know-more-btn'>
								Know more
							</button>
						</ScrollIntoView>
					</div>
				</div>
				<div className='header-img-div'>
					<img src={banner} alt='' loading='lazy' />
				</div>
			</section>

			{/* What is Disecto all about? */}
			<section className='container about-us' id='about-us'>
				<div className='flex'>
					<div className='about-img-div'>
						<img src={disectoAbout} alt='About' />
					</div>
					<div className='about-text-div'>
						<h1>What is Disecto all about?</h1>
						<p>
							At Disecto, we provide SaaS based Data Discovery,
							Classification and a remediation solution for data privacy
							compliance. We also have a <a className='highlight'>PET (Privacy Enhancing Technology) </a>
							based solution for aggregated data analytics over encrypted data.
							<br /> <br />
							Data is the new ‘oil’ of the 21st century. It is the driver of the
							digitised world economy. Overcome the new challenges of
							Data Privacy and Data Security with our Digital Security Tools.
							<br /> <br />
							Disecto empowers your organisation with Data Privacy
							Compliance to International Data Privacy laws like GDPR,
							HIPPA, Data Gov. Act, SCC, DSA, DMA, AI.
						</p>
					</div>
				</div>
			</section>

			{/* Why users privacy is important? */}
			<section className='container privacy-imp' id='privacy-imp'>
				<div className='privacy-imp-text-div'>
					<h1 className='privacy-imp-title'> Why user's privacy is important?
					</h1>
					<p>
						For one, it allows individuals to maintain control over their
						personal information and how it is used. This can help
						prevent unwanted or malicious uses of that information, such
						as <a className='highlight'>identity theft</a> or fraud. Additionally, protecting privacy can
						help foster trust between individuals and the organizations
						or entities that collect and use their personal data.
					</p>

					<div style={{
						display: 'flex',
						alignItems: 'baseline',
					}}>
						<ScrollIntoView selector='#paradigm' smooth={true} style={{ color: '#39C0BA', fontWeight: 'bold', fontSize: '20px' }}>
							See our solution in action
						</ScrollIntoView>
						<ScrollIntoView selector='#paradigm' smooth={true}>
							<img src={rightArrow} className='arrow' alt='' style={{ marginLeft: '0.5rem' }} />
						</ScrollIntoView>
					</div>
				</div>
				<div className='privacy-imp-img-div'>
					<img src={privacyImage} alt='Disecto' />
				</div>
			</section>

			{/*Paradigm Shifting Technology*/}
			<section className='container solutions' id='paradigm'>
				<h1 className='solutions-heading-title'>Our paradigm shifting technology</h1>
				<div className='solutions-card-div'>
					<Card className='solutions-card-wrapper'>
						<div>
							<img className='solutions-card-img' src={incogIcon} alt='Incog'></img>
						</div>
						<div>
							<h1 className='solution-header-title'> Incog </h1>
							<p>
								Data Privacy Management platform for complete
								compliance to data privacy laws. Secure and remediate
								your sensitive data and PII data vulnerabilities.
							</p>
							<hr className='horizontal-line' />
						</div>
						<div className='solutions-points-div'>
							<div className='solutions-points-col'>
								<Bullet type='check'>Data  Discovery </Bullet>
								<Bullet type='check'>Data  Anonymisation</Bullet>
							</div>
							<div className='solutions-points-col'>
								<Bullet type='check'>Data Classification</Bullet>
								<Bullet type='check'>Data Loss Prevention</Bullet>
							</div>
						</div>
						<div className='solutions-link' style={{ display: 'flex', alignItems: 'baseline', }}>
							<Link to={`${'/solution/INCOG'}`} onClick={scrollToTop} style={{ color: '#39C0BA', fontWeight: 'bold', fontSize: '18px', marginTop: '25px' }} >
								Learn more about Incog
							</Link>
							<Link style={{ color: '#39C0BA' }}>
								<img src={rightArrow} className='arrow' alt='' style={{ marginLeft: '0.5rem' }} />
							</Link>
						</div>
					</Card>
					<Card className='solutions-card-wrapper'>
						<div>
							<img className='solutions-card-img' src={ekaantIcon} alt='Ekaant'></img>
						</div>
						<div>
							<h1 className='solution-header-title'> Ekaant </h1>
							<p>
								Our futuristic Privacy Preserving data analytics platform to
								unlock insights on sensitive data by using <a className='highlight'>homomorphic
								encryption</a>.
							</p>
							<hr className='horizontal-line' />
						</div>
						<div className='solutions-points-div'>
							<div className='solutions-points-col'>
								<Bullet type='check'>Homomorphic Encryption</Bullet>
								<Bullet type='check'>Zero Knowledge Proofs</Bullet>
							</div>
							<div className='solutions-points-col'>
								<Bullet type='check'>Decentralized Network</Bullet>
								<Bullet type='check'>Blockchain Technology</Bullet>
							</div>
						</div>
						<div className='solutions-link' style={{ display: 'flex', alignItems: 'baseline', }}>
							<Link to={`${'/solution/EKAANT'}`} onClick={scrollToTop} style={{ color: '#39C0BA', fontWeight: 'bold', fontSize: '18px', marginTop: '25px' }} >
								Learn more about Ekaant
							</Link>
							<Link style={{ color: '#39C0BA' }}>
								<img src={rightArrow} className='arrow' alt='' style={{ marginLeft: '0.5rem' }} />
							</Link>
						</div>
					</Card>
				</div>
			</section>

			{/* Articles on Data Privacy */}
			<section className='container articles'>
				<h1 className='articles-heading-title'> Articles on Data Privacy</h1>
				<Swiper modules={[Navigation, Pagination, Autoplay]}
					breakpoints={{
						640: {
							slidesPerView: 1,
							spaceBetween: 10,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 10,
						},
						1024: {
							slidesPerView: 3,
							spaceBetween: 10,
						},
					}}
					navigation
					pagination={{ clickable: true }}
					autoplay={{ delay: 3000 }} loop={true}
					autoplayDisableOnInteraction={false}
					onSwiper={(swiper) => console.log(swiper)}
					onSlideChange={() => console.log('slide change')}
				>
					{blogs.map((blog, blogId) => (
						<>
							<SwiperSlide >
								<div className='articles-div'>
									<Blogcard key={blogId} {...blog} />
								</div>
							</SwiperSlide>
						</>
					))}
				</Swiper>
			</section>

			{/* Footer */}
			<Footer />
		</div>
	);
}

export default Home;
