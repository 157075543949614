import React, { useState } from "react";

// Styles
import "../styles/uses.scss";

// Footer component
import Footer from "./footer";

// Assets
import telecomImage from "../assets/telecom-image.svg";
import healthImage from "../assets/health-image.svg";
import financeImage from "../assets/finance-image.svg";
import businessImage from "../assets/business-image.svg";
import telecomIcon from "../assets/telecom-icon.png";
import healthIcon from "../assets/health-icon.png";
import financeIcon from "../assets/finance-icon.png";
import businessIcon from "../assets/business-icon.png";

function UseCases() {
  // Setting current screen width to state in real time
  const [width, setWidth] = useState(window.screen.width);
  window.addEventListener("resize", () => {
    setWidth(window.screen.width);
  });

  // Use cases data array
  const useCasesData = [
    {
      id: 0,
      title: "Telecom Sector",
      description:
        "Telecommunication providers gather user data on every interaction their customers perform. Disecto helps telecommunications to safely share customer data between business units and across geographic borders, reducing time and cost to market when developing new services.",
      icon: telecomIcon,
      image: telecomImage,
    },
    {
      id: 1,
      title: "Health Sector",
      description:
        "Modern healthcare companies are heavily reliant on data. Disecto helps the healthcare industry to develop new data-based business models and services without compromising the privacy of users. Our solution helps in strengthening the trust among the stakeholders by ensuring data is treated and handled safely.",
      icon: healthIcon,
      image: healthImage,
    },
    {
      id: 2,
      title: "Finance Sector",
      description:
        "Disecto offers banks instant analytics compliant with complex regulations. This provides a dramatic reduction in turn-around time for new projects and an increased ability to share data internally. Our solution helps the banking sector to reduce time and cost to achieve compliance.",
      icon: financeIcon,
      image: financeImage,
    },
    {
      id: 3,
      title: "B2C Sector",
      description:
        "B2C Industries will be required to adopt new data sharing methods to comply with PDP. Disecto will provide them with complete compliance solutions and help them save on expenditure in establishing data privacy teams.",
      icon: businessIcon,
      image: businessImage,
    },
  ];

  return (
    <>
      <section className="container use-cases" id="use-cases">
        <h1>Use cases</h1>
        <div className="use-cases-div">
          {useCasesData.map((el, key) => (
            <div
              className={`use-case ${
                width > 768 && !(key % 2) ? "row-reverse" : ""
              }`}
            >
              <div className="use-case-img-div">
                <img src={el.image} alt="" />
              </div>
              <div className="use-case-text-div">
                <div className="use-case-title">
                  <img src={el.icon} alt="" />
                  <h2>{el.title}</h2>
                </div>
                <p>{el.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </>
  );
}

export default UseCases;
