import React from 'react';

// Router
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Components
import Nav from './screens/Nav';
import Home from './screens/Home';
// import OurSolution from './screens/OurSolution';
import ContactUs from './screens/ContactUs';
import Team from './screens/Team';
import UseCases from './screens/UseCases';
import PageNotFound from './screens/404';
import IncogClDemo from './screens/demo/IncogClDemo';
import IncogDlpDemo from './screens/demo/IncogDlpDemo';
import EKAANT from './screens/solution/EKAANT';
import INCOG from './screens/solution/INCOG';
// import BlogsHome from './screens/BlogsHome';
// import Blog from './screens/Blog';
import Healthcare from './screens/usecases/Healthcare';
import RealEstate from './screens/usecases/RealEstate';
import Finance from './screens/usecases/Finance';
import PrivacyPolicy from './screens/PrivacyPolicy';
import ScrollToTop from './utils/ScrollToTop';

function App() {
	return (
		<Router>
			<ScrollToTop />
			<Nav />
			<Switch>
				<Route path='/' exact component={Home} />
				{/* <Route path='/solution' exact component={OurSolution} /> */}
				<Route path='/solution/EKAANT' exact component={EKAANT} />
				<Route path='/solution/INCOG' exact component={INCOG} />
				<Route path='/uses' exact component={UseCases} />
				{/* <Route path='/team' exact component={Team} /> */}
				<Route path='/contact' exact component={ContactUs} />
				{/* <Route path='/blogs' exact component={BlogsHome} />
				<Route path='/blogs/:id' exact component={Blog} /> */}
				<Route path='/privacy-policy' exact component={PrivacyPolicy} />

				{/* Use cases */}
				<Route
					path='/usecase/healthcare'
					exact
					component={Healthcare}
				/>
				<Route path='/usecase/finance' exact component={Finance} />
				<Route
					path='/usecase/real-estate'
					exact
					component={RealEstate}
				/>

				{/* Demo Routes */}
				<Route
					path='/incog-classification-demo'
					exact
					component={IncogClDemo}
				/>
				<Route path='/incog-dlp-demo' exact component={IncogDlpDemo} />

				{/* 404 Page Not Found */}
				<Route path='*' component={PageNotFound} />
			</Switch>
		</Router>
	);
}

export default App;
