import React, { useState } from 'react';

// Styles
import '../styles/nav.scss';

// GSAP animation library
// import { gsap } from 'gsap';

// Assets
import logo from '../assets/logo.svg';
import logoWhite from '../assets/logo-white.svg';
import menu from '../assets/icon-hamburger.svg';
import cross from '../assets/icon-close.svg';

// React router
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';

function Nav() {
	const [showSidebar, setShowSidebar] = useState(false);
	// Functions for displaying and hiding mobile nav menu
	const showMenu = () => {
		setShowSidebar(true);
	};

	const hideMenu = () => {
		setShowSidebar(false);
	};

	// Highlight desktop nav on scroll
	const [nav, setNav] = useState(false);

	window.addEventListener('scroll', () => {
		if (window.scrollY > 60) setNav(true);
		else setNav(false);
	});

	const history = useHistory();
	const location = useLocation();
	const splitLocation = location.pathname.split('/');

	const usecases = [
		{
			name: 'Healthcare',
			link: '/usecase/healthcare',
		},
		{
			name: 'Finance',
			link: '/usecase/finance',
		},
		{
			name: 'Real Estate',
			link: '/usecase/real-estate',
		},
	];

	const solutions = [
		{
			name: 'EKAANT',
			link: '/solution/EKAANT',
		},
		{
			name: 'INCOG',
			link: '/solution/INCOG',
		},
	];

	return (
		<div className='container'>
			{/* Desktop Nav */}
			<nav
				className={`nav desktop-nav ${
					nav ? 'highlight-desktop-nav' : ''
				}`}
			>
				<div className='logo'>
					<NavLink to='/'>
						<img src={logo} alt='' />
					</NavLink>
				</div>
				<div className='desktop-nav-links'>
					<NavLink to='/' exact activeStyle={{ color: '#39c0ba' }}>
						About
					</NavLink>

					{/* Solution Menu */}
					<Menu>
						<MenuButton
							ml='6'
							mr='2'
							// as={Button}
							background='transparent'
							fontWeight={600}
							fontSize={['18px']}
							_hover={{ color: '#39c0ba' }}
							color={
								splitLocation[1] === 'solution'
									? '#39c0ba'
									: '#444444'
							}
							// rightIcon={<ChevronDownIcon />}
						>
							Solution <ChevronDownIcon />
						</MenuButton>

						<MenuList>
							<MenuItem
								onClick={() => {
									history.push('/solution/EKAANT');
								}}
								style={{
									color:
										splitLocation[2] === 'EKAANT'
											? '#39c0ba'
											: '#444444',
								}}
							>
								EKAANT
							</MenuItem>
							<MenuItem
								onClick={() => {
									history.push('/solution/INCOG');
								}}
								style={{
									color:
										splitLocation[2] === 'INCOG'
											? '#39c0ba'
											: '#444444',
								}}
							>
								INCOG
							</MenuItem>
						</MenuList>
					</Menu>

					{/* Use Cases Menu */}
					<Menu>
						<MenuButton
							ml='6'
							mr='2'
							// as={Button}
							background='transparent'
							fontWeight={600}
							fontSize={['18px']}
							_hover={{ color: '#39c0ba' }}
							color={
								splitLocation[1] === 'usecase'
									? '#39c0ba'
									: '#444444'
							}
							// rightIcon={<ChevronDownIcon />}
						>
							Use Cases <ChevronDownIcon />
						</MenuButton>

						<MenuList>
							<MenuItem
								onClick={() => {
									history.push('/usecase/healthcare');
								}}
								style={{
									color:
										splitLocation[2] === 'healthcare'
											? '#39c0ba'
											: '#444444',
								}}
							>
								Healthcare
							</MenuItem>
							<MenuItem
								onClick={() => {
									history.push('/usecase/finance');
								}}
								style={{
									color:
										splitLocation[2] === 'finance'
											? '#39c0ba'
											: '#444444',
								}}
							>
								Finance
							</MenuItem>
							<MenuItem
								onClick={() => {
									history.push('/usecase/real-estate');
								}}
								style={{
									color:
										splitLocation[2] === 'real-estate'
											? '#39c0ba'
											: '#444444',
								}}
							>
								Real Estate
							</MenuItem>
						</MenuList>
					</Menu>

					{/* <NavLink
						to="/solution"
						activeStyle={{ color: "#39c0ba" }}
						
					>
						Solution
					</NavLink> 
					<NavLink
						to="/uses"
						activeStyle={{ color: "#39c0ba" }}
						
					>
						Use cases
					</NavLink> */}

					{/* <NavLink to='/team' activeStyle={{ color: '#39c0ba' }}>
						Team
					</NavLink> */}
					{/* <NavLink
						to='/blogs'
						activeStyle={{ color: '#39c0ba' }}
						
					>
						Blog
					</NavLink> */}
					<NavLink to='/contact' activeStyle={{ color: '#39c0ba' }}>
						Contact
					</NavLink>
				</div>
			</nav>

			{/* Mobile Nav */}
			<nav
				className={`nav mobile-nav ${
					nav ? 'highlight-mobile-nav' : ''
				}`}
			>
				<div className='nav-header'>
					<NavLink to='/'>
						<img src={logo} alt='' />
					</NavLink>
					<img src={menu} onClick={showMenu} alt='' />
				</div>
				<motion.div
					className='mobile-nav-menu'
					animate={showSidebar ? 'open' : 'closed'}
					transition={{
						type: 'tween',
						duration: 0.5,
					}}
					variants={{
						open: {
							opacity: 1,
							transform: 'translateY(0px)',
						},
						closed: {
							opacity: 0,
							transform: 'translateY(-100%)',
						},
					}}
				>
					<div className='nav-header'>
						<NavLink
							to='/'
							onClick={() => {
								hideMenu();
							}}
						>
							<img src={logoWhite} alt='' />
						</NavLink>
						<img src={cross} onClick={hideMenu} alt='' />
					</div>
					<div className='mobile-nav-links'>
						<NavLink
							to='/'
							exact
							activeStyle={{
								borderBottom: '6px solid #ffffff',
								color: '#aeecea',
							}}
							onClick={hideMenu}
						>
							About
						</NavLink>

						<Accordion
							allowToggle
							gap='5px'
							display='flex'
							flexDirection='column'
						>
							{/* Solutions */}
							<AccordionItem border='none'>
								<AccordionButton
									background='transparent'
									fontWeight={600}
									fontSize={['26px']}
									color={
										splitLocation[1] === 'solution'
											? '#aeecea'
											: '#ffffff'
									}
									_hover={{
										background: 'transparent',
										color: '#aeecea',
									}}
									_focus={{
										background: 'transparent',
										color: '#aeecea',
									}}
									position='relative'
									className='nav-link'
								>
									<div
										style={{
											position: 'relative',
										}}
									>
										Solutions
										<ChevronDownIcon
											style={{
												position: 'absolute',
												right: '-1em',
												top: '0.25em',
											}}
										/>
									</div>
								</AccordionButton>
								<AccordionPanel padding={0}>
									{solutions.map((solution, index) => (
										<div
											key={index}
											onClick={() => {
												history.push(solution.link);
												hideMenu();
											}}
											className='accordion-panel-item'
											style={{
												textDecoration:
													splitLocation[2] ===
													solution.link.split('/')[2]
														? 'underline'
														: 'none',
											}}
										>
											{solution.name}
										</div>
									))}
								</AccordionPanel>
							</AccordionItem>

							{/* Use Cases */}
							<AccordionItem border={'none'}>
								<AccordionButton
									background='transparent'
									fontWeight={600}
									fontSize={['26px']}
									color={
										splitLocation[1] === 'usecase'
											? '#aeecea'
											: '#ffffff'
									}
									_hover={{
										background: 'transparent',
										color: '#aeecea',
									}}
									_focus={{
										background: 'transparent',
										color: '#aeecea',
									}}
									position='relative'
									className='nav-link'
								>
									<div
										style={{
											position: 'relative',
										}}
									>
										Use Cases
										<ChevronDownIcon
											style={{
												position: 'absolute',
												right: '-1em',
												top: '0.25em',
											}}
										/>
									</div>
								</AccordionButton>
								<AccordionPanel padding={0}>
									{usecases.map((useCase, index) => (
										<div
											key={index}
											onClick={() => {
												history.push(useCase.link);
												hideMenu();
											}}
											className='accordion-panel-item'
											style={{
												textDecoration:
													splitLocation[2] ===
													useCase.link.split('/')[2]
														? 'underline'
														: 'none',
											}}
										>
											{useCase.name}
										</div>
									))}
								</AccordionPanel>
							</AccordionItem>
						</Accordion>

						{/* <NavLink
							to="/solution"
							exact
							activeStyle={{
								borderBottom: "6px solid #ffffff",
								color: "#aeecea",
							}}
							onClick={hideMenu}
							>
							Our Solution
						</NavLink>

						<NavLink
							to='/uses'
							exact
							activeStyle={{
								borderBottom: '6px solid #ffffff',
								color: '#aeecea',
							}}
							onClick={hideMenu}
							style={{ marginTop: '32px' }}
						>
							Use Cases
						</NavLink> */}

						<NavLink
							to='/team'
							exact
							activeStyle={{
								borderBottom: '6px solid #ffffff',
								color: '#aeecea',
							}}
							onClick={hideMenu}
						>
							Our Team
						</NavLink>

						{/* <NavLink
							to='/blogs'
							activeStyle={{
								borderBottom: '6px solid #ffffff',
								color: '#aeecea',
							}}
							onClick={hideMenu}
						>
							Our Blog
						</NavLink> */}

						<NavLink
							to='/contact'
							exact
							activeStyle={{
								boxShadow: 'none',
								background: '#39c0ba',
								border: '2px solid #ffffff',
								color: '#ffffff',
								opacity: '0.6',
							}}
							onClick={hideMenu}
							className='contact-us-link'
						>
							Contact us
						</NavLink>
					</div>
				</motion.div>
			</nav>
		</div>
	);
}

export default Nav;
