import React from 'react';
import { PrimaryButton } from '../../components/Button';
import { Link, useHistory } from 'react-router-dom';
import healthcareBanner from '../../assets/healthcare-header.svg';
import financeBanner from '../../assets/usecase/finance-banner.svg';
import realEstateBanner from '../../assets/usecase/real-estate-banner.svg';
import Card from '../../components/Card';

export default function BottomDemo({ to }) {
	const history = useHistory();

	const linkTo = (to) => {
		history.push(to);
	};
	return (
		<>
			<div className='schedule-demo-container'>
				<h1 className='primary-header-title'>
					Curious about how it all works?
				</h1>
				<p className='quote'>
					Connect with us for a deep-dive of our privacy preserving
					solutions
				</p>
				<Link to={to}>
					<PrimaryButton>Schedule a demo</PrimaryButton>
				</Link>

				<h2>Check out our industry specific solutions</h2>
				{/* TODO: Add a carousel */}
				<div className='usecase-sectors-container'>
					<Card onClick={() => linkTo('/usecase/healthcare')}>
						<img src={healthcareBanner} alt='' />
						<h3>Healthcare</h3>
						<p>
							Keep your PHI (Personal Healthcare Information)
							secure with Incog
						</p>
					</Card>
					<Card onClick={() => linkTo('/usecase/finance')}>
						<img src={financeBanner} alt='' />
						<h3>Finance</h3>
						<p>
							Discover & Classify PII data within your
							organization with Incog
						</p>
					</Card>
					<Card onClick={() => linkTo('/usecase/real-estate')}>
						<img src={realEstateBanner} alt='' />
						<h3>Real Estate</h3>
						<p>
							Secure your Real Estate data with Disecto’s Incog
							DLP
						</p>
					</Card>
				</div>
			</div>
		</>
	);
}
