import React from "react";

// Styles
import "../../styles/solution.scss";

// Footer component
import Footer from "../footer";

// Assets
import tickImage from "../../assets/tick.svg";
import secureImage from "../../assets/secure.svg";
import growthImage from "../../assets/growth.svg";

function EKAANT() {
  // Solution cards data array
  const ourSolutionData = [
    {
      title: "Decentralised Framework",
      description:
        "Multiparty computation subverts the need for a central trusted authority and reduces the requirement for local storage. It is also appropriate for scalability while maintaining data confidentiality",
    },
    {
      title: "Privacy Enhancing Technologies (PET)",
      description:
        "Ekaant includes an amalgamation of PET that can be used by governments to publish demographic information or other statistical aggregates while ensuring confidentiality of survey responses, and it can also be used by companies to collect information about user behavior while controlling what is visible even to internal analysts.",
    },
    {
      title: "Homomorphic Encryption",
      description:
        "End-to-end data protection using Homomorphic Encryption i.e. Data will remain encrypted during storage, in transit and even during computation",
    },
    {
      title: "Blockchain",
      description:
        "Ekaant uses Immutable decentralised  ledgers to ensure the execution of queries and to maintain data quotas.",
    },
  ];

  const whyEkaantData = [
    {
      id: 0,
      image: growthImage,
      description: [
        "Gain Greater Insights by performing analysis on private data",
        "Using the power of anonymity for unbiased and fair data-sharing",
      ],
    },
    {
      id: 1,
      image: secureImage,
      description: [
        "Preserves privacy even in the presence of strong adversary",
        "Enables auditability of encrypted data",
      ],
    },
    {
      id: 2,
      image: tickImage,
      description: [
        "Protection from Litigation costs",
        "Saving the cost from privacy breach penalties",
      ],
    },
  ];

  return (
    <>
      <section className="container solution" id="solution">
        <div className="solution-header">
          <h1>
            Our Solution - <span className="ekaant">EKAANT</span>
          </h1>
          <p>
            Our state-of-the-art Decentralised Privacy preserving framework,
            Ekaant maximizes data utility and minimises the security risks.
            Ekaant is modular, scalable and packs an array of Privacy Enhancing
            Technologies (PET). Our framework is quantum safe and future proof.
          </p>
        </div>

        <div className="solution-cards">
          {ourSolutionData.map((el) => (
            <div className="solution-card">
              <h2>{el.title}</h2>
              <p>{el.description}</p>
            </div>
          ))}
        </div>

        <div className="why-ekaant-section">
          <h1>Why Ekaant?</h1>
          <div className="why-ekaant-grid">
            {whyEkaantData.map((el) => (
              <div className="why-ekaant-column">
                <img src={el.image} alt="" />
                <ul>
                  {el.description.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </>
  );
}

export default EKAANT;
