import React from 'react';

// Styles
import '../styles/404.scss';

// Images
import PNFLogo from '../assets/404.svg';

// React router and scroll libraries
import { Link } from 'react-router-dom';

// Button component
import { PrimaryButton } from '../components/Button';

export default function PageNotFound() {
	return (
		<div className='pnf-container'>
			<img className='pnf-logo' src={PNFLogo} alt='404' />
			<div className='png-right'>
				<h1>404 - Page not found</h1>
				<p>
					The page you are looking for might have either been removed, had its
					name changed or is temporarily unavailale.
				</p>
				<Link to='/'>
					<PrimaryButton>Go to homepage</PrimaryButton>
				</Link>
			</div>
		</div>
	);
}
