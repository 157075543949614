import React from 'react';

// Styles
import '../styles/team.scss';

// Assets
import manavImage from '../assets/team/manav-alt.png';
import aindransushreeImage from '../assets/team/aindransushree-image.svg';

import rimjimImage from '../assets/team/Rimjim.png';
import karanImage from '../assets/team/karan-image.svg';
// import LinkedinLogo from "../assets/team/linkedin32.png";

import namitaimage from '../assets/team/Namita.jpg';
import nishthaimage from '../assets/team/nishtha.jpg';
import venkateshimage from '../assets/team/venkatesh.jpg';
import KapilImage from '../assets/team/kapil.jpg';
import KrishnaImage from '../assets/team/krishna-veer.jpg';
import RohitImage from '../assets/team/rohit-bisht.jpg';
import DipanshuImage from '../assets/team/dipanshu-saini.jpg';
import OmImage from '../assets/team/om-varshith.jpg';
import SharadImage from '../assets/team/sharad-kushwah.jpg';
import SaifImage from '../assets/team/md-saif-ahmed.jpg';
import NimishImage from '../assets/team/nimish-jain.jpg';
import AshutoshImage from '../assets/team/ashutosh.jpg';
import gauravImage from '../assets/team/gaurav-rane.jpg';
import ameeshaImage from '../assets/team/ameesha.jpg';

// Footer component
import Footer from './footer';

function Team() {
	// Teams data array
	const managementTeamData = [
		{
			name: 'Manav Mahajan',
			image: manavImage,
			position: 'Co-Founder & CEO',
			linkedin: 'https://www.linkedin.com/in/manav2994/',
		},
		{
			name: 'Aindransushree Ray',
			image: aindransushreeImage,
			position: 'Co-Founder & CTO',
			linkedin:
				'https://www.linkedin.com/in/aindransushree-ray-64580a1b9/',
		},
		{
			name: 'Karan Goel',
			image: karanImage,
			position: 'Co-Founder & COO',
			linkedin: 'https://www.linkedin.com/in/karangoel95/',
		},
	];

	const coreTeamData = [
		{
			name: 'Rimjim Sharma',
			image: rimjimImage,
			position: 'Product Manager',
			linkedin: 'https://www.linkedin.com/in/rimjim/',
		},
		{
			name: 'Namita Ashodia',
			image: namitaimage,
			position: 'Penetration Tester',
			linkedin: 'https://www.linkedin.com/in/namita-ashodia-58a17a18a/',
		},
		{
			name: 'Venkatesh Surve',
			image: venkateshimage,
			position: 'Software Developer',
			linkedin: 'https://angel.co/u/venkatesh-surve',
		},
		{
			name: 'Kapil Bahal',
			image: KapilImage,
			position: 'Product Manager',
			linkedin: 'https://www.linkedin.com/in/kapilbahal/',
		},
		{
			name: 'Krishna Veer Singh',
			image: KrishnaImage,
			position: 'Software Developer',
			linkedin:
				'https://www.linkedin.com/in/krishna-veer-singh-30b36517a/',
		},
		{
			name: 'Rohit Bisht',
			image: RohitImage,
			position: 'Backend Developer',
			linkedin: 'https://www.linkedin.com/in/rohit-bisht-328597212',
		},
		{
			name: 'Gaurav Rane',
			image: gauravImage,
			position: 'Privacy Engineer',
			linkedin: 'https://www.linkedin.com/in/gaurav-rane1209/',
		},
	];

	const internsData = [
		{
			name: 'Nishtha Rajan',
			image: nishthaimage,
			position: 'UI/UX Design',
			linkedin: 'https://www.linkedin.com/in/nishtha-rajan-b9a584237/',
		},
		{
			name: 'Dipanshu Saini',
			image: DipanshuImage,
			position: 'UI/UX Design',
			linkedin: 'https://www.linkedin.com/in/dipanshu-381864227/',
		},
		{
			name: 'Nukarapu Om Datha Varshith',
			image: OmImage,
			position: 'Backend Developer',
			linkedin:
				'https://www.linkedin.com/in/nukarapu-om-datha-varshith-51498417a/',
		},
		{
			name: 'Sharad Kushwah',
			image: SharadImage,
			position: 'Frontend Developer',
			linkedin: 'https://www.linkedin.com/in/sharad-kushwah/',
		},
		{
			name: 'Nimish Jain',
			image: NimishImage,
			position: 'Frontend Developer',
			linkedin: 'https://www.linkedin.com/in/nimishjn/',
		},
		{
			name: 'Ameesha Gireesh',
			image: ameeshaImage,
			position: 'Frontend Developer',
			linkedin: 'https://www.linkedin.com/in/ameeshagireesh/',
		},
		{
			name: 'Md Saif Ahmed',
			image: SaifImage,
			position: 'Python Developer',
			linkedin: 'https://www.linkedin.com/in/md-saif-ahmed-1305a2194/',
		},
		{
			name: 'Ashutosh Singh',
			image: AshutoshImage,
			position: 'DevOps Engineer',
			linkedin: 'https://www.linkedin.com/in/ashutoshsingh5786/',
		},
	];

	// Redirects to linkedin profiles
	const linkedinRedirect = (link) => {
		window.open(link, '_blank');
	};

	return (
		<>
			<section className='container team' id='team'>
				<div className='team-header'>
					<h1>Our team</h1>
					<h4>Leadership</h4>
				</div>
				<div className='team-cards-div'>
					{managementTeamData.map((el) => (
						<div
							className='team-card'
							onClick={() => linkedinRedirect(el.linkedin)}
						>
							<img src={el.image} alt='' />
							<h2>{el.name}</h2>
							<h4>{el.position}</h4>
						</div>
					))}
				</div>

				<div className='team-header'>
					<h4>Core team</h4>
				</div>
				<div className='team-cards-div'>
					{coreTeamData.map((el) => (
						<div
							className='team-card'
							onClick={() => linkedinRedirect(el.linkedin)}
						>
							<img src={el.image} alt='' />
							<h2>{el.name}</h2>
							<h4>{el.position}</h4>
						</div>
					))}
				</div>

				<div className='team-header'>
					<h4>Interns</h4>
				</div>
				<div className='team-cards-div'>
					{internsData.map((el) => (
						<div
							className='team-card'
							onClick={() => linkedinRedirect(el.linkedin)}
						>
							<img src={el.image} alt='' />
							<h2>{el.name}</h2>
							<h4>{el.position}</h4>
						</div>
					))}
				</div>
			</section>

			{/* Footer */}
			<Footer />
		</>
	);
}

export default Team;
