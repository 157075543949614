import React, { useState } from 'react';

// Styles
import '../styles/contact.scss';

// Assets
import contactImage from '../assets/contact-image.svg';
import Footer from './footer';

// Emailjs library
import emailjs from 'emailjs-com';

// Toast messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { emailRegEx } from '../utils/Constants';

function validateEmail(email) {
	return emailRegEx.test(String(email).toLowerCase());
}

const notify = (msg, type) => {
	if (type === 'success') {
		return toast.success(msg, {
			position: 'bottom-center',
			autoClose: 4000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	} else if (type === 'error') {
		return toast.error(msg, {
			position: 'bottom-center',
			autoClose: 4000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
};

function ContactUs() {
	// Spam reduction input
	const [spamEmail, setSpamEmail] = useState('');
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [message, setMessage] = useState('');
	const [charLimit, setCharLimit] = useState(0);

	// Function to send email from form response
	function sendEmail(e) {
		e.preventDefault();

		// Cancel form submission if spam email field has any value
		if (spamEmail) return;

		// Validations
		if (!name || !email || !message) {
			notify('Please fill all the fields', 'error');
			return;
		}

		// Email validation
		if (!validateEmail(email)) {
			notify('Please enter a valid email address', 'error');
			return;
		}

		// Message length secondary validation
		if (message.length > 1024) {
			notify('Only 1024 characters allowed in the message', 'error');
			return;
		}

		emailjs
			.sendForm(
				'service_mnldy7n',
				'template_jdapogu',
				e.target,
				'user_jrzw1n4oXcfnoqL930xIU'
			)
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);

		notify('Your message has been sent successfully', 'success');
		setName('');
		setEmail('');
		setMessage('');
	}

	return (
		<>
			<section className='container contact-us' id='contact-us'>
				<div className='contact-us-header'>
					<h1>Contact us</h1>
					<p>
						For any queries send us an email at{' '}
						<span className='email'>contact@disecto.com</span>{' '}
					</p>
					<p>Or write us a message & we will get back to you!</p>
				</div>
				<div className='contact-us-div'>
					<div className='contact-us-img-div'>
						<img src={contactImage} alt='' />
					</div>
					<div className='contact-form-div'>
						<form
							onSubmit={sendEmail}
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							{/* Name input */}
							<input
								type='text'
								placeholder='Name'
								name='from_name'
								maxLength={24}
								onChange={(e) => setName(e.target.value)}
								value={name}
							/>

							{/* Spam reduction input */}
							<input
								type='email'
								placeholder='Email'
								value={spamEmail}
								style={{ display: 'none' }}
								onChange={(e) => setSpamEmail(e.target.value)}
							/>

							{/* Email input */}
							<input
								// type="email"
								placeholder='Email'
								name='from_email'
								onChange={(e) => setEmail(e.target.value)}
								value={email}
							/>

							{/* Message input */}
							<textarea
								id=''
								cols='30'
								rows='4'
								placeholder='Your message'
								name='message'
								onChange={(e) => {
									setMessage(e.target.value);
									setCharLimit(e.target.value.length);
								}}
								maxLength={1024}
								style={{ resize: 'none' }}
								value={message}
							/>
							<p style={{ marginLeft: 'auto', color: '#AAAAAA' }}>
								{charLimit}/1024
							</p>
							<button
								type='submit'
								className='btn contact-us-btn'
							>
								Contact us
							</button>
						</form>
					</div>
				</div>
			</section>

			{/* Toast container */}
			<ToastContainer
				position='bottom-center'
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* Footer */}
			<Footer />
		</>
	);
}

export default ContactUs;
