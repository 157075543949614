import React from "react";
import '../styles/blogcard.scss';

const Blogcard = ({ blogTitle, imageURL, publishDate, readTime, blogURL }) => {

    return (
        <a href={blogURL} className="blogcard-container">
            <img className="blogcard-img" alt='blog pic' src={imageURL}></img>
            <div className="blogcard-text">
                <p> {publishDate} | {readTime}
                </p>
            </div>
            <div className="blogcard-title">
                <h3 className="blogcard-title">{blogTitle}</h3>
            </div>

        </a>
    )
}

export default Blogcard;