import React from 'react';
import { Box } from '@chakra-ui/react';

// Stylings
import '../../styles/incog.scss';

// Images
import incogBanner from '../../assets/data-classification.svg';
import healthcareBanner from '../../assets/healthcare-header.svg';
import financeBanner from '../../assets/usecase/finance-banner.svg';
import realEstateBanner from '../../assets/usecase/real-estate-banner.svg';

// Components
import Bullet from '../../components/Bullet';
import Card from '../../components/Card';
import { PrimaryButton } from '../../components/Button';
import Footer from '../footer';

// Router components
import { Link, useHistory } from 'react-router-dom';

function INCOG() {
	const history = useHistory();

	const linkTo = (to) => {
		history.push(to);
	};
	return (
		<>
			<Box
				display='flex'
				flexDirection={'column'}
				justifyContent={'center'}
				alignItems={'center'}
				w='100%'
			>
				<section
					className='container incog-header'
					style={{
						flexDirection: 'column',
						width: '100%',
						alignItems: 'center',
						textAlign: 'center',
					}}
				>
					<div
						className='incog-header-text-div'
						style={{
							width: '80%',
							padding: '0',
						}}
					>
						<h1 className='incog-header-title'>
							Our Solution -{' '}
							<span
								style={{
									color: '#39C0BA',
								}}
							>
								INCOG
							</span>
						</h1>
						<p>
							Incog is a state-of-the-art data privacy management
							suite that meets all the requirements of your
							organisation for protecting your users’ data
							privacy. It is your one stop solution for complete
							adherence to data privacy regulations across the
							globe such as GDPR, HIPPA, Data Gov. Act, SCC, DSA,
							DMA, AI Act to name a few.
						</p>
						<Link to='/incog-classification-demo'>
							<PrimaryButton>Schedule a demo</PrimaryButton>
						</Link>
					</div>
					<div
						className='incog-header-img-div'
						style={{
							width: '90%',
						}}
					>
						<img
							style={{
								boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
							}}
							src={incogBanner}
							alt=''
							loading='lazy'
						/>
					</div>
					<div className='incog-card-div'>
						<Card className='incog-card-wrapper'>
							<h1>What you get with Incog?</h1>
							<div className='incog-points'>
								<Bullet type='box'>
									User’s consent management
								</Bullet>
								<Bullet type='box'>Data Discovery</Bullet>
								<Bullet type='box'>Data Classification</Bullet>
								<Bullet type='box'>Incident Reporting</Bullet>
								<Bullet type='box'>
									Regulatory Remediation
								</Bullet>
							</div>
						</Card>
						<Card className='incog-card-wrapper'>
							<h1>Why do you need Incog?</h1>
							<div className='incog-points'>
								<Bullet type='nav'>
									Complete Data Privacy Compliance
								</Bullet>
								<Bullet type='nav'>
									Cost-effective data management
								</Bullet>
								<Bullet type='nav'>
									Protection from Penalties
								</Bullet>
								<Bullet type='nav'>
									Efficient Data Breach Management
								</Bullet>
								<Bullet type='nav'> Smooth Auditing </Bullet>
							</div>
						</Card>
					</div>
				</section>
				<div className='usecase-sector-container'>
					<h1 className='primary-header-title'>
						Sectors it can be used in
					</h1>
					<div className='sectors-container'>
						<Card onClick={() => linkTo('/usecase/healthcare')}>
							<img src={healthcareBanner} alt='' />
							<h3>Healthcare</h3>
							<p>
								Keep your PHI (Personal Healthcare Information)
								secure with Incog
							</p>
						</Card>
						<Card onClick={() => linkTo('/usecase/finance')}>
							<img src={financeBanner} alt='' />
							<h3>Finance</h3>
							<p>
								Discover & Classify PII data within your
								organization with Incog
							</p>
						</Card>
						<Card onClick={() => linkTo('/usecase/real-estate')}>
							<img src={realEstateBanner} alt='' />
							<h3>Real Estate</h3>
							<p>
								Secure your Real Estate data with Disecto’s
								Incog DLP
							</p>
						</Card>
					</div>
				</div>
			</Box>
			<Footer />
		</>
	);
}

export default INCOG;
