import React from 'react';

import { Box, Text } from '@chakra-ui/react';

// Styles
import '../../styles/footer.scss';
import PoweredBy from './PoweredBy';

function Footer() {
	return (
		<>
			<PoweredBy />

			{/* Main Footer */}
			<Box
				width={'100%'}
				d='flex'
				flexDirection={'column'}
				justifyContent={'center'}
				alignItems={'center'}
				backgroundColor={'#121F2B'}
			>
				<Box
					w='100%'
					h='12px'
					background={
						'linear-gradient(90.26deg, #39C0BA -8.08%, #4FEAE3 108.59%)'
					}
				></Box>
				<Text
					// px={[10]}
					p={[5]}
					textAlign={'center'}
					color={'#DBDBDB'}
					// mb={[8, null, null, 6]}
					// fontWeight={'bold'}
				>
					&copy; Copyright <b> 2023, Disecto Technologies Pvt. Ltd.</b> All Rights Reserved
				</Text>
			</Box>
		</>
	);
}

export default Footer;
