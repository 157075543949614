import React from 'react';

export default function Bullet({
	type = 'check',
	children = 'none',
	style,
	...props
}) {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				gap: '0.5em',
				alignItems: 'center',
				...style,
			}}
			{...props}
		>
			<div
				style={{
					flexShrink: 0,
					marginBottom: '0.15em',
				}}
			>
				<BulletIcon type={type} />
			</div>
			<p>{children}</p>
		</div>
	);
}

const BulletIcon = ({ type }) => {
	switch (type) {
		case 'check':
			return <CircularCheckIcon />;
		case 'box':
			return <BoxCheckIcon />;
		case 'nav':
			return <NavigationIcon />;
		default:
			return <CircularCheckIcon />;
	}
};

const CircularCheckIcon = () => (
	<svg
		width='1.5em'
		height='1.5em'
		viewBox='0 0 36 36'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M18 1.5C8.8875 1.5 1.5 8.8875 1.5 18C1.5 27.1125 8.8875 34.5 18 34.5C27.1125 34.5 34.5 27.1125 34.5 18C34.5 8.8875 27.1125 1.5 18 1.5ZM25.152 15.21C25.2837 15.0595 25.384 14.8841 25.4469 14.6943C25.5098 14.5044 25.5341 14.3039 25.5184 14.1045C25.5027 13.9051 25.4473 13.7108 25.3554 13.5332C25.2635 13.3555 25.137 13.1981 24.9833 13.07C24.8297 12.942 24.652 12.8459 24.4606 12.7876C24.2693 12.7292 24.0683 12.7097 23.8693 12.7302C23.6704 12.7507 23.4775 12.8108 23.3021 12.9069C23.1267 13.0031 22.9723 13.1333 22.848 13.29L16.398 21.0285L13.0605 17.6895C12.7776 17.4163 12.3987 17.2651 12.0054 17.2685C11.6121 17.2719 11.2359 17.4297 10.9578 17.7078C10.6797 17.9859 10.5219 18.3621 10.5185 18.7554C10.5151 19.1487 10.6663 19.5276 10.9395 19.8105L15.4395 24.3105C15.5869 24.4578 15.7633 24.5728 15.9576 24.6481C16.1519 24.7234 16.3597 24.7574 16.5679 24.748C16.7761 24.7385 16.98 24.6858 17.1666 24.5932C17.3533 24.5005 17.5186 24.3701 17.652 24.21L25.152 15.21Z'
			fill='#39C0BA'
		/>
	</svg>
);

const BoxCheckIcon = () => (
	<svg
		width='1.5em'
		height='1.5em'
		viewBox='0 0 20 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M7.5 9.61784L10 12.1178L16.6667 5.45117'
			stroke='#39C0BA'
			strokeWidth='1.66667'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M16.6673 10.4508V15.4508C16.6673 15.8929 16.4917 16.3168 16.1792 16.6294C15.8666 16.9419 15.4427 17.1175 15.0007 17.1175H5.00065C4.55862 17.1175 4.1347 16.9419 3.82214 16.6294C3.50958 16.3168 3.33398 15.8929 3.33398 15.4508V5.45085C3.33398 5.00882 3.50958 4.5849 3.82214 4.27233C4.1347 3.95977 4.55862 3.78418 5.00065 3.78418H12.5007'
			stroke='#39C0BA'
			strokeWidth='1.66667'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

const NavigationIcon = () => (
	<svg
		width='1.5em'
		height='1.5em'
		viewBox='-2 -2 19 19'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M16.7809 2.04925C16.9723 1.58441 16.8629 1.04925 16.5074 0.693782C16.152 0.338313 15.6168 0.228938 15.152 0.420344L1.40196 6.04534C0.847275 6.27191 0.534775 6.85784 0.648056 7.44378C0.761337 8.02972 1.27696 8.45159 1.87462 8.45159H8.74962V15.3266C8.74962 15.9243 9.17149 16.436 9.75743 16.5532C10.3434 16.6703 10.9293 16.3539 11.1559 15.7993L16.7809 2.04925Z'
			fill='#39C0BA'
		/>
	</svg>
);
