import React, { useRef, useState } from 'react';

// Styles
import '../../styles/demo.scss';

// Assets
import demoImage from '../../assets/data-classification.svg';
import Footer from '../footer';

// Emailjs library
import emailjs from 'emailjs-com';

// Toast messages
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PrimaryButton } from '../../components/Button';
import Bullet from '../../components/Bullet';
import { emailRegEx } from '../../utils/Constants';

function validateEmail(email) {
	return emailRegEx.test(String(email).toLowerCase());
}

const notify = (msg, type) => {
	if (type === 'success') {
		return toast.success(msg, {
			position: 'bottom-center',
			autoClose: 4000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	} else if (type === 'error') {
		return toast.error(msg, {
			position: 'bottom-center',
			autoClose: 4000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
};

function IncogClDemo() {
	// Spam requireduction input
	const demoForm = useRef(null);
	const [loading, setLoading] = useState(false);

	// Function to send email from form response
	async function sendEmail(e) {
		e.preventDefault();

		const firstName = demoForm.current[0].value;
		const lastName = demoForm.current[1].value;
		const email = demoForm.current[2].value;
		const jobTitle = demoForm.current[3].value;
		const company = demoForm.current[4].value;
		const agreed = demoForm.current[5].checked;

		// Validations
		if (
			!firstName ||
			!lastName ||
			!email ||
			!jobTitle ||
			!company ||
			!agreed
		) {
			notify('Please fill all the fields', 'error');
			return;
		}

		// Email validation
		if (!validateEmail(email)) {
			notify('Please enter a valid email address', 'error');
			return;
		}

		setLoading(true);

		await emailjs
			.sendForm(
				'service_mnldy7n',
				'template_nwn16bi',
				e.target,
				'user_jrzw1n4oXcfnoqL930xIU'
			)
			.then(
				(result) => {
					console.log(result.text);
					notify(
						'Your message has been sent successfully',
						'success'
					);
					setLoading(false);
					e.reset();
				},
				(error) => {
					console.log(error.message);
					notify(
						'Some unknown error occured. Please try again later.',
						'error'
					);
					setLoading(false);
				}
			);
		
			setLoading(false);
	}

	return (
		<>
			<section className='container demo' id='demo'>
				<div className='demo-div'>
					<div className='demo-left'>
						<h1>Schedule an Incog Classification Demo</h1>
						<img src={demoImage} alt='' />
						<p className='demo-description'>
							Incog is a state-of-the-art data privacy management
							suite that meets all the requirements of your
							organisation for protecting your users’ data
							privacy. It is your one stop solution for complete
							adherence to data privacy regulations across the
							globe such as GDPR, HIPPA, Data Gov. Act, SCC, DSA,
							DMA, AI Act to name a few.
						</p>
						<div className='key-points'>
							<Bullet type='check'>
								User’s Consent Management
							</Bullet>
							<Bullet type='check'>Data Discovery</Bullet>
							<Bullet type='check'>Data classification</Bullet>
							<Bullet type='check'>Incident Reporting</Bullet>
							<Bullet type='check'>Regulatory Remediation</Bullet>
						</div>
					</div>
					<div className='form-div'>
						<h1>Schedule Demo</h1>
						<p className='demo-form-note'>
							<span style={{ color: '#39C0BA' }}>Note:</span> All
							fields marked with{' '}
							<span className='required'>*</span> are required.
						</p>
						<form
							onSubmit={sendEmail}
							ref={demoForm}
							className='demo-form-container'
						>
							{/* Name inputs */}
							<div className='name-container'>
								{/* First Name input */}
								<div className='input-container'>
									<label htmlFor='first_name'>
										First Name{' '}
										<span className='required'>*</span>
									</label>
									<input
										type='text'
										name='from_first_name'
										id='first_name'
										maxLength={24}
									/>
								</div>

								{/* Last Name input */}
								<div className='input-container'>
									<label htmlFor='last_name'>
										Last Name{' '}
										<span className='required'>*</span>
									</label>
									<input
										type='text'
										name='from_last_name'
										id='last_name'
										maxLength={24}
									/>
								</div>
							</div>

							{/* Work Email input */}
							<div className='input-container'>
								<label htmlFor='work_email'>
									Work Email{' '}
									<span className='required'>*</span>
								</label>
								<input name='work_email' id='work_email' />
							</div>

							{/* Job Title input */}
							<div className='input-container'>
								<label htmlFor='job_title'>
									Job Title{' '}
									<span className='required'>*</span>
								</label>
								<input name='job_title' id='job_title' />
							</div>

							{/* Company input */}
							<div className='input-container'>
								<label htmlFor='company'>
									Company <span className='required'>*</span>
								</label>
								<input name='company' id='company' />
							</div>

							{/* Agreement Checkbox */}
							<label
								htmlFor='agreement'
								style={{
									color: '#555555',
									fontSize: '12px',
								}}
								className='agreement-label'
							>
								<input
									type='checkbox'
									name='agreement'
									id='agreement'
								/>{' '}
								I agree to receive email communications
								regarding Disecto products and services. 
								{/* You can learn more about our handling and processing of
								your personal data as described in our */}
								{' '}
								<a
									href='/privacy-policy'
									target='_blank'
									rel='noreferrer'
									style={{
										color: '#39C0BA',
										textDecoration: 'none',
									}}
								>
									{/* privacy policy */}
								</a>
								{/* . <span className='required'>*</span> */}
							</label>

							{/* Hidden element for Demo Type */}
							<input
								type='hidden'
								name='demo_type'
								value='Incog Classification Demo'
							/>

							{/* Submit button */}
							<PrimaryButton type='submit' disabled={loading}>
								{loading ? 'Submitting...' : 'Schedule a Demo'}
							</PrimaryButton>
						</form>
					</div>
				</div>
			</section>

			{/* Toast container */}
			<ToastContainer
				position='bottom-center'
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			{/* Footer */}
			<Footer />
		</>
	);
}

export default IncogClDemo;
