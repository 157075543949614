import React from 'react';

// Incubators images
// import iHub from '../../assets/powered/iHub.svg';
// import IITKanpur from '../../assets/powered/iit-kanpur.svg';
// import startupIndia from '../../assets/powered/startup-india.svg';
// import indiaAcclerator from '../../assets/powered/india-accelerator.svg';
// import C3iHub from '../../assets/powered/c3iHub.svg';
// import microsoft from '../../assets/powered/microsoft-for-startup.svg';
// import ncoe from '../../assets/powered/ncoe.svg';
// import dsci from '../../assets/powered/dsci.svg';

// Clients data array
// const clientData = [
// 	{ img: iHub, link: 'https://ihubgujarat.in/' },
// 	{ img: IITKanpur, link: 'https://siicincubator.com/' },
// 	{ img: startupIndia, link: 'https://www.startupindia.gov.in/' },
// 	{ img: microsoft, link: 'https://www.microsoft.com/en-us/startups' },
// 	{ img: indiaAcclerator, link: 'https://www.indiaaccelerator.co/' },
// 	{ img: ncoe, link: 'https://www.n-coe.in/' },
// 	{ img: dsci, link: 'https://www.dsci.in/' },
// 	{ img: C3iHub, link: 'https://c3ihub.org/' },
// ];

// Images
import one from '../../assets/powered/1.svg';
import two from '../../assets/powered/2.svg';
import three from '../../assets/powered/3.svg';
import four from '../../assets/powered/4.svg';
import five from '../../assets/powered/5.svg';
import six from '../../assets/powered/6.svg';
import seven from '../../assets/powered/7.svg';

const clientData = [
	{ img: one, link: 'https://c3ihub.org/' },
	{ img: two, link: 'https://www.n-coe.in/' },
	{ img: three, link: 'https://ihubgujarat.in/' },
	{ img: four, link: 'https://www.microsoft.com/en-us/startups' },
	{ img: five, link: 'https://www.indiaaccelerator.co/' },
	{ img: six, link: 'https://www.startupindia.gov.in/' },
	{ img: seven, link: 'https://ddtii.in/' },
];

export default function PoweredBy() {
	return (
		<section className='container footer'>
			<h1>Powered by</h1>
			<div className='client-images'>
				{clientData.map((el, i) => (
					<a
						href={el.link}
						key={i}
						target='_blank'
						rel='noreferrer noopener'
						className='client-image'
					>
						<img src={el.img} alt='' />
					</a>
				))}
			</div>
		</section>
	);
}
