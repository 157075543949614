import React from 'react';

// Styles
import '../../styles/usecases.scss';

// Footer component
import Footer from '../footer';

// React router and scroll libraries
import { Link } from 'react-router-dom';

// Button component
import { PrimaryButton } from '../../components/Button';

import banner from '../../assets/usecase/healthcare-banner.svg';
import incogBanner from '../../assets/data-classification.svg';
import Card from '../../components/Card';
import Bullet from '../../components/Bullet';
import multiSourceBanner from '../../assets/usecase/multi-source-discovery.svg';
import robustBanner from '../../assets/usecase/robust-classification-healthcare.svg';
import singleWindowBanner from '../../assets/usecase/single-window-remediation.svg';
import BottomDemo from './BottomDemo';

export default function Healthcare() {
	return (
		<div>
			<section className='container usecase-header'>
				<div className='usecase-header-text-div'>
					<h1 className='usecase-header-title'>Healthcare</h1>
					<p>
						Being part of the healthcare sector, you have to deal
						with an abundance of patient data which contains
						sensitive data (PHI- Personal Healthcare Information).
						To protect this information and make the best use of the
						non-personal data you need a secured, accurate, and
						trustworthy method that will enable you to locate PHI
						across your organization's digital presence.
						<br />
						Incog- Classification provides the most efficient,
						secured and easy-to-use data discovery solution for your
						organization
					</p>
					<div className='buttons'>
						<Link to='/incog-classification-demo'>
							<PrimaryButton>Schedule a demo</PrimaryButton>
						</Link>
					</div>
				</div>
				<div className='usecase-header-img-div'>
					<img src={banner} alt='' loading='lazy' />
				</div>
			</section>

			<section
				className='container usecase-header'
				style={{
					flexDirection: 'column',
					width: '100%',
					alignItems: 'center',
					textAlign: 'center',
					paddingTop: 100,
				}}
			>
				<div
					className='usecase-header-text-div'
					style={{
						width: '80%',
						padding: '0',
					}}
				>
					<h1 className='usecase-header-title-2'>
						Incog at a glance
						<hr />
					</h1>
					<p
						style={{
							textAlign: 'center',
						}}
					>
						Incog is a state-of-the-art data privacy management
						suite that meets all the requirements of your
						organisation for protecting your users’ data privacy. It
						is your one stop solution for complete adherence to data
						privacy regulations across the globe such as GDPR,
						HIPPA, Data Gov. Act, SCC, DSA, DMA, AI Act to name a
						few.
					</p>
				</div>
				<div
					className='usecase-header-img-div'
					style={{
						width: '100%',
						marginTop: -40,
					}}
				>
					<img
						style={{
							boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.1)',
						}}
						src={incogBanner}
						alt=''
						loading='lazy'
					/>
				</div>
				<div className='usecase-card-div'>
					<Card className='usecase-card-wrapper'>
						<h1>What you get with Incog?</h1>
						<div className='usecase-points'>
							<Bullet type='box'>
								User’s consent management
							</Bullet>
							<Bullet type='box'>Data Discovery</Bullet>
							<Bullet type='box'>Data Classification</Bullet>
							<Bullet type='box'>Incident Reporting</Bullet>
							<Bullet type='box'>Regulatory Remediation</Bullet>
						</div>
					</Card>
					<Card className='usecase-card-wrapper'>
						<h1>Why do you need Incog?</h1>
						<div className='usecase-points'>
							<Bullet type='nav'>
								Complete Data Privacy Compliance
							</Bullet>
							<Bullet type='nav'>
								Cost-effective data management
							</Bullet>
							<Bullet type='nav'>
								Protection from Penalties
							</Bullet>
							<Bullet type='nav'>
								Efficient Data Breach Management
							</Bullet>
							<Bullet type='nav'> Smooth Auditing </Bullet>
						</div>
					</Card>
				</div>
			</section>
			<div className='reachout-container'>
				<h3>Reach out to us now!</h3>
				<Link to='/incog-classification-demo'>
					<PrimaryButton>Schedule a demo</PrimaryButton>
				</Link>
			</div>
			<div className='keyfeature-container'>
				<h1 className='primary-header-title'>
					Key features of Incog
					<hr />
				</h1>

				{/* Section 1 */}
				<section className='keyfeature-section ltr'>
					<div className='keyfeature-section-img-div'>
						<img src={multiSourceBanner} alt='' loading='lazy' />
					</div>
					<div className='keyfeature-section-text-div'>
						<h2 className='keyfeature-section-title'>
							Multi-source discovery
						</h2>
						<p className='keyfeature-section-description'>
							Whether your personal health care data lies within
							your premises or on the cloud, whether it is
							structured or unstructured data we’ve got you
							covered. You can locate the PHI’s irrespective of
							their sources.
						</p>
						<div className='keyfeature-points-container'>
							<Bullet type='check'>Structured Data</Bullet>
							<Bullet type='check'>Unstructured Data</Bullet>
							<Bullet type='check'>Local Data</Bullet>
							<Bullet type='check'>Cloud Data</Bullet>
						</div>
					</div>
				</section>

				{/* Section 2 */}
				<section className='keyfeature-section rtl'>
					<div className='keyfeature-section-img-div'>
						<img src={robustBanner} alt='' loading='lazy' />
					</div>
					<div className='keyfeature-section-text-div'>
						<h2 className='keyfeature-section-title'>
							Robust Classification
						</h2>
						<p className='keyfeature-section-description'>
							We have industry standardised classification types
							for your personal healthcare data but there’s always
							room for more! You can customise the classification
							as per your need and create custom classification of
							your PHI.
						</p>
						<div className='keyfeature-points-container'>
							<Bullet type='check'>Custom Classification</Bullet>
							<Bullet type='check'>
								Healthcare Specific Classification
							</Bullet>
						</div>
					</div>
				</section>

				{/* Section 3 */}
				<section className='keyfeature-section ltr'>
					<div className='keyfeature-section-img-div'>
						<img src={singleWindowBanner} alt='' loading='lazy' />
					</div>
					<div className='keyfeature-section-text-div'>
						<h2 className='keyfeature-section-title'>
							Single Window Remediation
						</h2>
						<p className='keyfeature-section-description'>
							Your data management team can take the necessary
							action on the personal healthcare data to meet
							regulatory compliances through one window only. This
							will ensure 100% data privacy law compliance and
							smooth auditing.
						</p>
						<div className='keyfeature-points-container'>
							<Bullet type='check'>PHI Anonymisation</Bullet>
							<Bullet type='check'>PHI Redaction</Bullet>
							<Bullet type='check'>PHI Encryption</Bullet>
							<Bullet type='check'>
								Cryptographic Shredding
							</Bullet>
						</div>
					</div>
				</section>
			</div>
			<BottomDemo to='/incog-classification-demo' />
			<Footer />
		</div>
	);
}
