import React from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import '../styles/privacyPolicy.scss';
import Footer from './footer';

export default function PrivacyPolicy() {
	const [markdown, setMarkdown] = React.useState('');
	fetch('/assets/markdown/privacy-policy.md')
		.then((response) => {
			return response.text();
		})
		.then((data) => {
			setMarkdown(data);
		})
		.catch((err) => console.log(err));
	return (
		<>
			<div className='privacy-container'>
				<ReactMarkdown>{markdown}</ReactMarkdown>
			</div>
			<Footer />
		</>
	);
}
